import { Injectable } from '@angular/core';
import { LoadingService } from './../loading/loading.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, finalize, retry, catchError } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

class PhoneBook {
  Index: number = 0;
  Name: string = '';
  Number: string = '';  
  Note: string = '';  
  GateCtrlFlag: boolean = false;
  MasterFlag: boolean = false;
  AdminFlag: boolean = false;
  EventMask: number[];
}

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  public phoneBook: PhoneBook;
  public phoneBooks: PhoneBook[] = [];
  public mainPhonebooks: PhoneBook[] = [];
  private apiPhoneBookList = `${environment.api}/phonebook/list`;
  
  constructor(
    public http: HttpClient,     
    public storage: Storage,
    public loadingService: LoadingService
  ) { 
    this.phoneBook = {
      Index: -1,
      Name :'',
      Number : '',
      Note: '',
      GateCtrlFlag: false,
      MasterFlag: false,
      AdminFlag: false,
      EventMask: []
    }
  }

  /* public addEntry() {  //Deprecated
    
    if (this.phoneBook.Index == -1) { //Update the Index based on the total length of items
      this.phoneBook.Index = this.mainPhonebooks.length+1;
    }
    console.log(this.phoneBook);
    this.mainPhonebooks.push(this.phoneBook);
  } */

  // public addPhonebook() {      
  //   //this.loadingService.present("Please Wait...",40000);
  //   if (this.phoneBook.Index == -1) { //Update the Index based on the total length of items
  //     this.phoneBook.Index = this.mainPhonebooks.length+1;
  //   }
  //   // this.mainPhonebooks.push(this.phoneBook);
  //   // var auth = window.btoa(environment.Username + ':' + environment.Password);
  //   // var headers = {"Authorization": "Basic " + auth};  
  //   // return this.http.post(`${environment.api}/phonebook/main`, this.mainPhonebooks, {headers: headers}).pipe(map((response: any) => response),    
  //   // finalize(() => {      
  //   //     this.loadingService.dismiss();
  //   // }));
  // }

  public savePhoneBook() { //Fix the newly created PhoneBook Index
    if (this.phoneBook.Index == -1) { //Update the Index based on the total length of items
      this.phoneBook.Index = this.mainPhonebooks.length+1;
      this.mainPhonebooks.push(this.phoneBook);
    }    
  }

  public deletePhoneBook(index) {
    var indexer = 0;
    for (var phoneBook of this.mainPhonebooks) {          
      if (index == phoneBook.Index) {
        this.mainPhonebooks[indexer].Name = "";
        this.mainPhonebooks[indexer].Number = "";
        this.mainPhonebooks[indexer].Note = "";
      }
      indexer++;  
    }
  }

  private getPhonebook(uid): Observable<Array<PhoneBook>> {  
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${this.apiPhoneBookList}/${uid}`, {headers: headers}).pipe(map((response: any) => response));
  }

  private getMainPhonebooks(): Observable<Array<PhoneBook>> {  
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${environment.api}/phonebook/main`, {headers: headers}).pipe(map((response: any) => response),
    finalize(() => {
        this.loadingService.dismiss();
    }));
  }  

  public updateMainPhonebooks() {      
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};  
    return this.http.post(`${environment.api}/phonebook/main`, this.mainPhonebooks, {headers: headers}).pipe(map((response: any) => response),    
    finalize(() => {      
        this.loadingService.dismiss();
    }));
  }

  findByIndex(index) {
    let phoneBooks: PhoneBook[];
    phoneBooks = this.phoneBooks.filter(phoneBooks => {
      return phoneBooks.Index.toString().indexOf(index.toString()) == 0;
    }); 
    if (phoneBooks.length>0) this.phoneBook = phoneBooks[0];     
  }



  /* subscribeAll(uid): Observable<boolean> {
        
        console.log(`About to subscribeAll() phoneBooks... for ${uid}`); 
        this.phoneBooks = null;
        this.mainPhonebooks = null;       
        {
          if (uid>-1) {
            this.getPhonebook(uid)
            .subscribe(
              phoneBooks => 
              { 
                this.phoneBooks = phoneBooks || [];  
                this.storage.set('phoneBooks',this.phoneBooks);              
                return true;      
              },
              error =>  {   
                console.log(JSON.stringify(error));
                return false;           
              });
          }
          
          this.getMainPhonebooks()
          .subscribe(
            mainPhonebooks => 
            { 
              this.mainPhonebooks = mainPhonebooks || [];                    
              return true;      
            },
            error =>  {   
              console.log(JSON.stringify(error));
              return false;           
            });
          
        }
    } */

    subscribeSingle(uid): Observable<PhoneBook[]> {
        
      console.log(`About to subscribeAll() phoneBooks... for ${uid}`); 
      this.phoneBooks = null;
      this.mainPhonebooks = null;    
      let obs = this.getPhonebook(uid);

      obs.subscribe(
        phoneBooks => 
        { 
          this.phoneBooks = phoneBooks || [];  
          this.storage.set('phoneBooks',this.phoneBooks);  
        }
      )

      return obs;
  }

  subscribeMain(): Observable<PhoneBook[]> {
        
    console.log(`About to subscribeMain() phoneBooks`); 
    this.phoneBooks = null;
    this.mainPhonebooks = null;    
    let obs = this.getMainPhonebooks();

    obs.subscribe(
      phoneBooks => 
      { 
        this.mainPhonebooks = phoneBooks || [];          
      }
    )

    return obs;
  }

  
  
}
