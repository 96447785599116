import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'ionic-angular';

let authURL = 'https://sabertek-iot-api.azurewebsites.net/api/auth';

class User {
  UserID: number = 0;
  Username: string = '';
  Password: string = '';
  Email: string = ''; 
  Avatar: string = '';
  FirstName: string = '';
  LastName: string = '';
  MobileNumber: string = '';
  SecondMobileNumber: string = '';
  PushoverUserKey: string = '';
  SMSCredits: number = 0;
  CallCredits: number = 0;
  CheckedAtRelative: string = '';
  BearerToken: string = '';
  TokenCreatedAt: Date = null;
}

class Auth {
  access_token: string = '';
  token_type: string = '';
  expires_in: number = 0;
  userName: string = '';
  expires_at: DateTime  ;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public user: User;

  constructor(public http: HttpClient) {
    this.user = {
      UserID : 0,
      Username : '',
      Password : '',
      Email : '',
      Avatar : '',
      FirstName: '',
      LastName: '',
      MobileNumber: '',
      SecondMobileNumber: '',
      PushoverUserKey: '',
      SMSCredits: 0,
      CallCredits: 0,
      CheckedAtRelative: '',
      BearerToken: '',
      TokenCreatedAt: null
    }
  }

  getBearerToken(username, password): Observable<any> {    
    let body = new HttpParams()
    .set('userName', username)
    .set('password', password)
    .set('grant_type', 'password');
    var headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    return this.http.post(authURL, body, {headers: headers}).pipe(map((response: any) => response));
  }
  
}
