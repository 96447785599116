import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { DateTime } from 'ionic-angular';
import { environment } from '../../../environments/environment';
import { LoadingService } from './../loading/loading.service';

export interface Profile {
  Username: string;
  Password: string;
  Name: string;
  Surname: string;
  Company: string;
  ContactNr: string;
  Email: string;
  NewPassword: string;
  NewPasswordRetyped: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public profile: Profile;

  constructor(public http: HttpClient,   
    public loadingService: LoadingService) {
    this.profile = {
      Username: '',
      Password: '',
      Company: '',
      ContactNr: '',
      Email: '',
      Name: '',
      Surname: '',
      NewPassword: '',
      NewPasswordRetyped: ''
    }
  }

  public getProfile(): Observable<Profile> {
    var auth = window.btoa(environment.Username + ':' + environment.Password);    
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${environment.api}/user/profile`, {headers: headers}).pipe(map((response: any) => response));
  }

  public updateProfile() {      
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};  
    return this.http.post(`${environment.api}/user/profile-update`, this.profile, {headers: headers}).pipe(map((response: any) => response),    
    finalize(() => {      
        this.loadingService.dismiss();
    }));
  }

  public loadDefaultEvents() {      
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};  
    return this.http.post(`${environment.api}/flosolve/default-events`, '', {headers: headers}).pipe(map((response: any) => response),    
    finalize(() => {      
        this.loadingService.dismiss();
    }));
  }
}
