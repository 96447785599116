import { ContactsService } from './../contacts/contacts.service';
import { LoadingService } from './../loading/loading.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, finalize, retry, catchError } from 'rxjs/operators';

export interface Genie {
  BOXID:         string;
  UID:           number;
  BID:           number;
  CSQ:           number;
  FwVer:         string;
  IMEI:          string;
  CCID:          string;
  CellNum:       string;
  AlarmState:    number;
  Synced:        boolean;
  Lat:           string;
  Lon:           string;
  GpsLastUpdate: string;
  VIN:           number;
  VBatt:         number;
  Thingstream:   boolean;
  Favorite:      boolean;
  LastSeen:      Date;
  ApiKey:        string;
  HttpUrl:       string;
  GroupIndex:    number;
  HWB:           number;
  IOX:           Iox[];
  DSP:           any[];
  IsWarningState:boolean;
  WarningStateUpdatedAtRelative: string;

  DeviceNickname:     string;
  IsDevicePowered:    boolean;
  LastSeenRelative:   string;
  WarningInputs:      string;

}

export interface Iox {
  IoxName:  string;
  UID:      number;
  MBUID:    number;
  BID:      number;
  FwVer:    string;
  Synced:   boolean;
  LastSeen: Date;
}

export interface DigitalKPI {
  Nickname: String;
  IsActive: boolean;
  HasBeenUpdated: boolean;
  State: boolean;
  UpdatedAt: Date;
  UpdatedAtRelative: String;
  DisplayState: number;
  NameList: string[];
  EscalationCount: number;
  EscalatedAt: Date;
  EscalatedAtRelative: String;
  IsWarningState: boolean;
  WarningStateUpdatedAt: Date;
}

export interface AnalogKPI {
  Nickname: String;
  IsActive: boolean;
  HasBeenUpdated: boolean;
  State: boolean;
  UpdatedAt: Date;
  UpdatedAtRelative: String;  
  DisplayState: number;
  Value: number;
  ValuePercentage: number; 
  SensorMin: number;
  SensorMax: number;
  NameList: string[];
  EscalationCount: number;
  EscalatedAt: Date;
  EscalatedAtRelative: String;
  IsWarningState: boolean;
  WarningStateUpdatedAt: Date;
 
}

export interface Dashboard {
  UID: number;
  CSQ: number;
  DeviceNickname: string;
  IsDevicePowered: boolean;
  LastSeenRelative: string;
  AclsLevel: DigitalKPI;
  AclsPress: AnalogKPI;
  AclsProxy: AnalogKPI;
  AclsPpwr: AnalogKPI;
  FilDp: AnalogKPI;
  OilFine: AnalogKPI;
  OilCoarse: AnalogKPI;
  OilWater: AnalogKPI;
  OilTemp: AnalogKPI;
  OilLevel: AnalogKPI;
  CftDp: AnalogKPI;
  IsAclsVisible: boolean;
  IsFilVisible: boolean;
  IsOilVisible: boolean;
  IsCftVisible: boolean;
  IsWarningState: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  public isGenieLoaded: boolean = false;
  public genie: Genie;
  public genies: Genie[] = [];
  public dashboard: Dashboard;
  public isStateToBeLoaded: boolean = false;
  public uid: number = 0;
  public logPath: string = '';

  constructor(
    public http: HttpClient,     
    public loadingService: LoadingService,
    public contactsService: ContactsService
  ) { 
    this.dashboard = {
      UID: 0,
      CSQ: 0,
      DeviceNickname: '',
      IsDevicePowered: false,
      LastSeenRelative: '',
      AclsLevel: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      AclsPress: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      AclsProxy: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      AclsPpwr: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      FilDp: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      OilFine: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0,  SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      OilCoarse: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0,  SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      OilWater: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0,  SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      OilTemp: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      OilLevel: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      CftDp: {Nickname: '', IsActive: false, HasBeenUpdated: false, State:false, UpdatedAt: new Date('1900-01-01'), DisplayState: -1, UpdatedAtRelative: '', Value: 0, ValuePercentage: 0, SensorMin:0, SensorMax: 0, NameList: [], EscalationCount : 0, EscalatedAt: new Date('1900-01-01'), EscalatedAtRelative: '', IsWarningState: false, WarningStateUpdatedAt: new Date('1900-01-01')},
      IsAclsVisible: false,
      IsFilVisible: false,
      IsOilVisible: false,
      IsCftVisible: false,
      IsWarningState: false      
    }

    this.genie = {
      DeviceNickname: '',
      IsDevicePowered: false,
      LastSeenRelative: '',
      BOXID:         '',
      UID:           0,
      BID:           0,
      CSQ:           0,
      FwVer:         '',
      IMEI:          '',
      CCID:          '',
      CellNum:       '',
      AlarmState:    0,
      Synced:        false,
      Lat:           '',
      Lon:           '',
      GpsLastUpdate: '',
      VIN:           0,
      VBatt:         0,
      Thingstream:   false,
      Favorite:      false,
      LastSeen:      null,
      ApiKey:        '',
      HttpUrl:       '',
      GroupIndex:    0,
      HWB:           0,
      IOX:           null,
      DSP:           null,
      IsWarningState:false,
      WarningStateUpdatedAtRelative: '',
      WarningInputs: ''
    }
  }

  private getAllGenies(): Observable<Array<Genie>> {    
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);    
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${environment.api}/genie/list`, {headers: headers}).pipe(map((response: any) => response),
    finalize(() => {
        this.loadingService.dismiss();
    }));
   
  }

  public getDashboard(uid, showState): Observable<Dashboard> {   
    var loadingMessage = '';    
    this.loadingService.present(`Please wait...`,40000); 
    var auth = window.btoa(environment.Username + ':' + environment.Password);    
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${environment.api}/flosolve/dashboard/${uid}`, {headers: headers}).pipe(map((response: any) => response),
    finalize(() => {
        this.loadingService.dismiss();
    }));
  }

  public getLog(uid): Observable<string> {   
    var loadingMessage = '';    
    this.loadingService.present(`Please wait...`,40000); 
    var auth = window.btoa(environment.Username + ':' + environment.Password);    
    var headers = {"Authorization": "Basic " + auth};   
    return this.http.get(`${environment.api}/flosolve/log/${uid}`, {headers: headers}).pipe(map((response: any) => response),
    finalize(() => {
        this.loadingService.dismiss();
    }));
  }

  update() {              
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};  
    return this.http.post(`${environment.api}/flosolve/update`, this.dashboard, {headers: headers}).pipe(map((response: any) => response),
    finalize(() => {
        this.loadingService.dismiss();
    }));
  }

  resetEscalation(uid,nickname) {              
    this.loadingService.present("Please Wait...",40000);
    var auth = window.btoa(environment.Username + ':' + environment.Password);
    var headers = {"Authorization": "Basic " + auth};  
    return this.http.post(`${environment.api}/flosolve/reset-escalation/${uid}/${nickname}`, '', {headers: headers}).pipe(map((response: any) => response),
    finalize(() => {
        this.loadingService.dismiss();
    }));
  }

  findByUID(uid) {
    let genies: Genie[];
    genies = this.genies.filter(genies => {
      return genies.UID.toString().indexOf(uid.toString()) > -1;
    }); 
    if (genies.length>0) 
    {
      this.genie = genies[0];
      this.isGenieLoaded = true;
    }     
  }

  subscribeAllGenies() {
    console.log('About to getAllGenies'); 
    this.getAllGenies()
    .subscribe(
      genies => 
      { 
        this.genies = genies || [];  
        //console.log(this.genies);
        return true;      
      },
      error =>  {   
        console.log(JSON.stringify(error));
        return false;           
      });
    }

    subscribeDashboard(uid, showState) {
      console.log('About to subscribeDashboard(uid, showState)');       
      this.getDashboard(uid, showState)
      .subscribe(
        dashboard => 
        { 
          this.dashboard = dashboard;                 
          return true;      
        },
        error =>  {   
          console.log(JSON.stringify(error));
          return false;           
        });
      }

      subscribeLog(uid) {
        console.log('About to create log(uid)');       
        this.getLog(uid)
        .subscribe(
          logPath => 
          { 
            this.logPath = logPath;                 
            return true;      
          },
          error =>  {   
            console.log(JSON.stringify(error));
            return false;           
          });
        }

      
}
