import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    currentLoading = null;
    constructor(public loadingController: LoadingController) {
    }

    async present(message: string = null, duration: number = null) {
        console.log('Loader PRESENTING');
        // Dismiss previously created loading
         if (this.currentLoading != null) {
          console.log('Dismiss previous PRESENTING');
            await this.currentLoading.dismiss();
        } 

        this.currentLoading = await this.loadingController.create({
            duration: duration,
            message: message
        });

        return await this.currentLoading.present();
    }

    async dismiss() {
      console.log('Loader DISMSS');
        if (this.currentLoading != null) {

            await this.loadingController.dismiss();
            this.currentLoading = null;
        }
        return;
    }

}