//import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ContactsService } from '../../services/contacts/contacts.service';
import { ToastController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.page.html',
  styleUrls: ['./contact-detail.page.scss'],
})
export class ContactDetailPage implements OnInit {
  toast:any;
  constructor(
    public contactsService: ContactsService,
    public toastController: ToastController,
    public modalController: ModalController,
    public navController: NavController,
  ) { }

  ngOnInit() {
  }
 
  processForm(){
    this.contactsService.savePhoneBook(); 
    this.modalController.dismiss(); 
  }

  deleteEntry(){
    this.contactsService.deletePhoneBook(this.contactsService.phoneBook.Index); 
    this.modalController.dismiss(); 
  }

  syncPhonebooks(){
    this.contactsService.updateMainPhonebooks()
    .subscribe(
      phonebooks =>       { 
        this.showToast(`Phonebooks Syncronised. Syncronise your Genies using the 'connect' SMS command`,'success');     
        this.modalController.dismiss();     
      },
      error =>  {              
        this.showToast(JSON.stringify(error),'danger');        
      }
    );
  }

  showToast(message, color) {
    this.toast = this.toastController.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      color: color,          
      closeButtonText: 'OK',
      showCloseButton: true       
    }).then((toastData)=>{      
      toastData.present();
    });
  }

}
