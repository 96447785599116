import { LoadingService } from './../loading/loading.service';
import { AuthenticationService } from './../authentication/authentication.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {_throw} from 'rxjs/observable/throw';
import { Storage } from '@ionic/storage';


@Injectable()
export class InterceptorProvider implements HttpInterceptor{

  constructor(
    private storage: Storage,
    public authService: AuthenticationService,
    public loadingService: LoadingService) {}

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //Dont add the Token when logging in or requesting the token    
    if ((request.url.indexOf('azurewebsites.net/api/auth')>-1) || (request.url.indexOf('api/user/login')>-1) || (request.url.indexOf('api/user/register')>-1))  
    //if ((request.url.indexOf('api/user/login')>-1) || (request.url.indexOf('api/user/register')>-1)) 
    {      
      return next.handle(request);
    }
    else{      
     
      var createdAt = this.authService.user.TokenCreatedAt;
      //console.log(`createdAt: ${createdAt}`);
      var now = new Date();  
      var expireAt = createdAt;
      //expireAt.setHours(expireAt.getHours() + 1); //TODO: Set to 3 hours based on JDs Token
      //Debug. Set expiry to 2 minutes
      expireAt.setMinutes(expireAt.getMinutes() + 2); //TODO: Set to 3 hours based on JDs Token
      //console.log(`expireAt: ${expireAt}`);
      //console.log(`now: ${now}`);

      if ((now >= expireAt) || (this.authService.user.BearerToken == null)){        
        if (now >= expireAt) console.log('Token expired');
        if (this.authService.user.BearerToken == null) console.log('Token set to NULL');
        //console.log(`expireAt: ${expireAt}`);
        //console.log(`now: ${now}`);
        //Try to get new token
        
        this.authService.getBearerToken(this.authService.user.Username,this.authService.user.Password).subscribe(
          auth => 
          {      
            //Success        
            //console.log('Got new Token');   
            var dateTime = new Date();
            this.authService.user.BearerToken = auth.access_token; 
            this.authService.user.TokenCreatedAt = dateTime; 
            //console.log('Use new Token');  
            let token = this.authService.user.BearerToken;
            let clonedReq = this.addToken(request, token);
            return next.handle(clonedReq);
          },
          error =>  {       
            //Error
            console.log('Error getting new Bearer Token: ' + JSON.stringify(error));        
            this.authService.user.BearerToken = null;   
            this.authService.user.TokenCreatedAt = null;              
        })
      }
      else{
        console.log('Token not expired yet...');
      }
      let token = this.authService.user.BearerToken;
      //console.log(token);
      let clonedReq = this.addToken(request, token);
      return next.handle(clonedReq);
    }
  }

  private addToken(request: HttpRequest<any>, token: any){   
    if (token){
      //console.log('Adding Token to header...');
      //console.log(token);
      let headers = request.headers
            .set('Content-Type', 'application/json')
            .set('Token', token);      
      let clone: HttpRequest<any>;
      clone = request.clone({headers});
      return clone;
    }
  }

}
