import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    //redirectTo: 'asset-list',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './pages/list/list.module#ListPageModule'
  },
  { path: 'asset-list', loadChildren: './pages/asset-list/asset-list.module#AssetListPageModule' },
  { path: 'contact-list', loadChildren: './pages/contact-list/contact-list.module#ContactListPageModule' },
  { path: 'contact-detail', loadChildren: './pages/contact-detail/contact-detail.module#ContactDetailPageModule' },
  { path: 'help', loadChildren: './pages/help/help.module#HelpPageModule' },
  { path: 'assign-roles', loadChildren: './pages/assign-roles/assign-roles.module#AssignRolesPageModule' },
  { path: 'preferences', loadChildren: './pages/preferences/preferences.module#PreferencesPageModule' },
  { path: 'client-list', loadChildren: './pages/client-list/client-list.module#ClientListPageModule' },
  { path: 'login', loadChildren: './modals/login/login.module#LoginPageModule' },
  { path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule' },  { path: 'user-detail', loadChildren: './pages/user-detail/user-detail.module#UserDetailPageModule' }


  


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
