import { UserDetailPageModule } from './pages/user-detail/user-detail.module';
import { LoginPageModule } from './modals/login/login.module';
import { LoginPage } from './modals/login/login.page';
import { ContactDetailPageModule } from './pages/contact-detail/contact-detail.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { InterceptorProvider } from './services/interceptor/interceptor.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [LoginPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    ContactDetailPageModule,
    UserDetailPageModule,
    IonicStorageModule.forRoot(),
    LoginPageModule
    
  ],
  providers: [
    { provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy},
      {provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true}     
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    library.add(fas)
  }
}
