import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { ToastController, ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.page.html',
  styleUrls: ['./user-detail.page.scss'],
})
export class UserDetailPage implements OnInit {
  toast: any;
  constructor(public clientService:ClientService,
    public toastController: ToastController,
    public modalController: ModalController,
    public navController: NavController,
    public storage: Storage
  ) { 
    
  }

  ngOnInit() {
  }

  updateProfile(isLogout){      
    console.log(this.clientService.profile);
    this.clientService.updateProfile()
    .subscribe(
      profile => 
      { 
        this.showToast('Changes Saved','success');  
        console.log(JSON.stringify(profile));
        this.modalController.dismiss();
        if (isLogout) this.logout();       
      },
      error =>  {
        this.showToast(error.error.Message,'danger');        
      }
    );  
  }

  provisionEvents(){
    console.log('Update to load default events');
    this.clientService.loadDefaultEvents()
    .subscribe(
      result => 
      { 
        this.showToast(`Changes Saved. Syncronise your Genies using the 'connect' SMS command. ${JSON.stringify(result)}`,'success');  
        console.log(JSON.stringify(result));
        this.modalController.dismiss();         
      },
      error =>  {
        this.showToast(JSON.stringify(error),'danger');        
      }
    );
  }

  processForm(){
    //Check if a New Password has been filled in
    
    if ((this.clientService.profile.NewPassword!=null) && (this.clientService.profile.NewPasswordRetyped!=null)){
      //Check to see if the New Passwords match
      if ((this.clientService.profile.NewPassword == this.clientService.profile.NewPasswordRetyped)){
        //Update the Password with New Password
       
        this.clientService.profile.Password = this.clientService.profile.NewPassword;
        this.updateProfile(true);
      } else {
        this.showToast("Your passwords don't match. Please try again", 'danger');
      }
    } else {
      this.updateProfile(false);
    }         
  }

  logout(){
    this.storage.set('username', null);
    this.storage.set('password', null);      
    this.navController.navigateBack('/landing'); 
  }

  showToast(message, color) {
    this.toast = this.toastController.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      color: color,          
      closeButtonText: 'OK',
      showCloseButton: true       
    }).then((toastData)=>{      
      toastData.present();
    });
  }

}
