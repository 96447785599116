import { ClientService } from './services/client/client.service';
import { Component } from '@angular/core';
import { ModalController, Platform,NavController } from '@ionic/angular';
import { AssetsService } from './services/assets/assets.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { Storage } from '@ionic/storage';
import { UserDetailPage } from '../app/pages/user-detail/user-detail.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  dataReturned: any;
  public appPages = [
    {
      title: 'Asset List', //View Dashboard OR Assign Roles WITH Search
      url: '/asset-list',
      icon: ['fas','th'],
      visible: true
    },
     {
      title: 'Phone Book',
      url: '/contact-list',
      icon: ['fas','phone'],
      visible: true
    }, 
    /* {
      title: 'FloSolve Help',
      url: '/help',
      icon: ['fas','question-circle'],
      visible: true
    } */
  ];

  constructor(    
    private navController: NavController,
    public assetsService: AssetsService,
    public authService: AuthenticationService,
    public clientService: ClientService ,   
    public storage: Storage ,
    public modalController: ModalController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    
  }


  logout(){
    this.storage.set('username', null);
    this.storage.set('password', null);      
    this.navController.navigateBack('/landing');     
  }

  showWebsite(){
    window.open("https://www.flosolve.co.za/",'_system', 'location=yes');
  }

  async openUserDetailModal() {
    const modal = await this.modalController.create({
      component: UserDetailPage,
      componentProps: {
        "paramID": 123,
        "paramTitle": "Test Title"
      }
    });
 
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert(' Data :'+ dataReturned);
      }
    });
 
    return await modal.present();
  }

}
